<template>
	<div>
        <Dialog :header="$t('Login')" v-model:visible="$store.state.display.login" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="false" :closable="false" style="background: #727376;position: absolute;">
            <div class="p-fluid">
                <div class="p-field p-grid">
                    <label class="p-col-12 p-md-2">{{$t('Email')}}</label>    
                    <div class="p-col-12 p-md-10">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <InputText v-model="email" type="email" autofocus id="corre" />
                        </div>
                    </div>
                </div>	
                <div class="p-field p-grid">
                    <label class="p-col-12 p-md-2">{{$t('Password')}}</label>
                    <div class="p-col-12 p-md-10">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-key"></i>
                            </span>
                            <InputText v-model="passw" type="password" @keyup.enter="Login" id="passw" />
                        </div>
                    </div>
                </div>
			</div>	
            <template #footer>
                <Button :label="$t('Accept')" icon="pi pi-check" class="p-button-secondary" @click="Login"/>
                <!--<Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="close"/>-->
                <Button :label="$t('Recover')" icon="pi pi-key" class="p-button-secondary" @click="openRecovery"/>
            </template>
        </Dialog>
        <Dialog :header="$t('Recover')" v-model:visible="displayRecovery" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="false" :closable="false" style="background: #727376;position: absolute;">
            <div class="p-fluid">
                <div class="p-field p-grid">
                    <label class="p-col-12 p-md-2">{{$t('Email')}}</label>    
                    <div class="p-col-12 p-md-10">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <InputText v-model="email" type="email" disabled autofocus id="email1" />
                        </div>
                    </div>
                </div>	
                <div class="p-field p-grid">
                    <label class="p-col-12 p-md-2">{{$t('Validation Code')}}</label>
                    <div class="p-col-12 p-md-10">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-unlock"></i>
                            </span>
                            <InputText v-model="code" type="text" @keyup.enter="Verificar" :disabled="validar == true"/>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12" v-if="!validar">
                        <div class="p-inputgroup">
                            {{$t('Validation Code Mens')}}
                        </div>
                    </div>
                </div>
                <div class="p-field p-grid" v-if="validar">
					<div class="p-col-12 p-md-6">
						<label>{{$t('Password')}}</label>
                        <InputText v-model="passw" type="password" id="passw"/>
					</div>
					<div class="p-col-12 p-md-6">
						<label>{{$t('Confirm Password')}}</label>
                        <InputText v-model="passwconf" type="password" id="passw0" />
					</div>
                    <div class="p-col-12 p-md-12">
						{{$t('Password Format')}}
					</div>
                </div>
			</div>	
            <template #footer>
                <Button :label="$t('Accept')" icon="pi pi-check" class="p-button-secondary" @click="Reset" v-if="validar"/>
                <Button :label="$t('Verify')" icon="pi pi-check" class="p-button-secondary" @click="ValidarCode" v-else/>
                <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="displayRecovery=false"/>
            </template>
        </Dialog>
	</div>
</template>

<script>
import API from "../service/API";
import Crypto from "crypto-js";
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
            i18n: null,
			displayLogin: false,
            displayRecovery: false,
            email: null,
            passw: null,
            passwconf: null,
            validar: false,
            code: null,
            codesend: null,
            size: '40vw',
            visibleFull: false
		}
	},
    created() {      
        this.i18n = useI18nPlugin();
        if (this.$store.state.mobile){this.size = '80vw';}
        this.email = process.env.VUE_APP_LOGIN;
        this.passw = process.env.VUE_APP_PASS;
    },
    methods: {
        limpiar() {
            this.passw = null;
            this.passwconf = null;
            this.validar = false;
            this.displayRecovery = false;
            this.code = null;
            this.codesend = null;
        },
        openRecovery() {
            if (this.email == null) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Enter email'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.email)) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Email error'), life: 10000});
            } else {
                this.$store.commit('Loading');
                const Consult = new API('Authentication','Reset');
                Consult.Process(null,{
                    email: this.email,
                    leng: this.i18n.locale()
                }).then(response => {
                    //this.$store.state.error = response;
                    this.$toast.add(Consult.Mens(response.email,this.i18n.locale(),this.$t(response.message)));
                    if(response.email){
                        this.codesend = response.code;
                        //this.displayLogin = false;
                        this.displayRecovery = true;
                    } 
                    this.$store.commit('Loading');                  
                });         
            }   
        },
        ValidarCode() {
            if (this.code == null) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Enter validation code'), life: 10000});
            } else {
                if (this.code == this.codesend) {
                    this.validar = true;
                } else {
                    this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Validation code error'), life: 10000});
                }
            }     
        },
        close() {
            this.limpiar();
            this.displayRecovery = false;
            //this.displayLogin = true;
        },
        Login() { 
            if (this.email == null) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Enter email'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.email)) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Email error'), life: 10000});
            } else if (this.passw == null) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Enter password'), life: 10000});
            } else {
                const cifrado = Crypto.HmacSHA1(this.passw, "btcoin").toString();
                this.$store.state.passw = cifrado;
                const Consult = new API('Authentication','Login');
                Consult.Process(null,{
                    email: this.email,
                    passw:  cifrado
                }).then(response => {
                    //this.$store.state.error = response;
                    this.$store.commit('Loading');
                    this.$toast.add(Consult.Mens(response.authenticated,this.i18n.locale(),this.$t(response.message)));
                    if(response.authenticated && response.menu){
                        this.$store.commit('Login',response);
                        this.$store.commit('Menu',response.menu);
                        this.$store.state.display.login = false;
                        this.$store.commit('Ini');
                    }
                });
                this.limpiar();
            }
        },
        Reset() {
            if (this.passw == null) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Password Error'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.email)) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Email error'), life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.passw)) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Password Error'), life: 10000});
            } else if (this.passw != this.passwconf) {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Confirmation Failed'), life: 10000});
            } else {
                this.$store.commit('Loading');
                const cifrado = Crypto.HmacSHA1(this.passw, "btcoin").toString();
                const Consult = new API('Authentication','Resetpassw');
                Consult.Process(null,{
                    email: this.email,
                    code: this.code,
                    passw: cifrado
                }).then(response => {
                    //this.$store.state.error = response;	
                    this.$toast.add(Consult.Mens(!response.error,this.i18n.locale(),this.$t(response.message)));
                    if(!response.error){
                        this.limpiar();
                        //this.displayRecovery = false;
                        //this.displayLogin = true;
                    }
                    this.$store.commit('Loading');
                });
            } 
        },
        onMenuToggle(event) {
            this.$emit('aprob-login', event);
            this.$emit('close-login', event);
        },	
	},
    watch: {
		display(newValue) {
			this.displayLogin = newValue;
		}
	},


}
</script>